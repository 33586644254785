//

.simulator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.simulator-head {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.sim-carousel {
  z-index: -1;
  margin-bottom: 30px;
}

.sim-carousel-img {
  position: relative;
  max-height: 60vh !important;
}

.sim-carousel-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.397);
  width: 100%;
  height: 100%;
  //   z-index: 1;
}

.sim-bold {
  font-weight: bold;
}

.sim-subhead {
  color: var(--heading);
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.sim-program-head {
  color: var(--heading);
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.2rem;
}

.sim-para {
  text-align: justify;
}

.sim-program-li {
  margin-bottom: 10px;
}

.fixed-sim-img-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.fixed-sim-img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin: 10px 20px;
  background-color: black;
}

@media screen and (min-width: 768px) {
  .fixed-sim-img {
    width: 300px;
    height: 300px;
  }
}
