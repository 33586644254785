.healthcare-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.health-head {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.health-description {
  text-align: justify;
}
