.partner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-head {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.partner-img-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.partner-img {
  width: 100px;
  margin: 20px;
  object-fit: contain;
}
\..partner-img-wide {
  width: 150px;
  margin: 20px;
  object-fit: contain;
}
