.services-container {
  background-color: #f6f9f8;
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   z-index: 0;
}

.services-heading {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.serv-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.serv-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
  background-color: white;
  width: 300px;
  height: 400px;

  -webkit-box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);
  box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);
  //   z-index: ;
}

.serv-card-wrap {
  position: relative;
  margin: 30px;
}

.serv-card-3d {
  position: absolute;
  width: 300px;
  height: 400px;
  left: -5px;
  bottom: -5px;
  background-color: white;
  -webkit-box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);
  box-shadow: -4px 6px 53px -3px rgba(0, 0, 0, 0.2);

  //   z-index: 0;
}

.serv-head-wrap {
  display: flex;
  align-items: end;
  height: 80px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.serv-heading {
  color: var(--heading);
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
  margin: 0;
}

.serv-caption {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--heading);
  height: 160px;
}

.serv-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: var(--secondary);
  color: white;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

.aviation-country {
  color: black;
}
