.aviation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.aviation-heading {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.aviation-item {
  border-style: solid;
  border: 10px;
  border-color: black;
  border: 1px solid black;
  padding: 5px;
  margin-top: 20px;
}

.avi-item-img {
  width: 100%;
}

.avi-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avi-item-head {
  color: var(--heading);
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.avi-item-para {
  text-align: justify;
}

.aviation-item-btn {
  all: unset;
  background-color: var(--secondary);
  color: white;
  padding: 10px 20px;
  transition: 300ms;
  width: 150px;
  height: 30px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aviation-item-btn:hover {
  color: var(--hover-color);

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media screen and (min-width: 992px) {
  .aviation-item {
    display: flex;
  }

  .avi-item-img {
    width: 50%;
    flex: 1;
  }

  .avi-item-content {
    flex: 1;
    padding-left: 10px;
  }
}
