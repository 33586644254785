.contact-container {
  background-color: var(--off-bg);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 150px);
  height: 100%;
}

.contact-wraper {
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  max-width: 600px;
  margin-bottom: 50px;
  background-color: var(--secondary);
}

.drop-a-line {
  background-color: white;
  color: var(--secondary);
  padding: 40px;
}

.drop-heading {
  color: var(--secondary);
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: bold;
}

.contact-input {
  color: var(--secondary);
  border: 1px solid;
  border-color: var(--secondary);
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
}

.contact-input::placeholder {
  color: var(--secondary);
  opacity: 0.75;
}

.contact-textarea {
  color: var(--secondary);
  border: 1px solid;
  border-color: var(--secondary);
  margin-bottom: 10px;
  width: 100%;
  height: 150px;
}

.contact-textarea::placeholder {
  color: var(--secondary);
  opacity: 0.75;
}

.contact-submit {
  all: unset;
  background-color: var(--secondary);
  color: white;
  padding: 10px 20px;
  transition: 300ms;
  width: 150px;
  height: 30px;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-submit:hover {
  // letter-spacing: 0.1rem;
  // width: 170px;
  color: var(--hover-color);

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.contact-submit:active {
  // font-size: 0.8rem;
  transform: scale(1.1);
  transition: 100ms;
}

.contact-submit-disabled {
  all: unset;
  background-color: rgb(90, 90, 90);
  color: white;
  padding: 10px 20px;
  transition: 300ms;
  width: 150px;
  height: 30px;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: not-allowed;
}

// ///////////////////////////
.contact-options {
  background-color: var(--secondary);
  color: var(--primary);
  padding: 40px;
  height: 100%;
}

.options-heading {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: bold;
}

.option {
  display: flex;
  flex-direction: row;
  // align-items: center;
  margin-bottom: 40px;
}

.option-icon-wrapper {
  min-width: 80px;
}

.option-icon {
  // width: 120px;
  height: 50px;
  margin-left: 0px;
  margin-right: 30px;
  text-align: left;
  align-self: flex-start;
}

.option-content {
  display: flex;
  flex-direction: column;
}

.option-content-title {
  font-size: 1.3rem;
}

.option-content-text {
  all: unset;
  font-size: 0.8rem;
}

.option-content-link:hover {
  color: var(--hover-color);
}

@media screen and (min-width: 992px) {
  // .contact-container {
  //   background-color: var(--off-bg);
  //   padding: 10px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }

  .contact-wraper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    max-width: 1200px;
  }

  .drop-a-line {
    flex: 1;
  }

  .contact-options {
    flex: 1;
    height: 100%;
  }
}
