.footer-container {
  width: 100vw;
  background-color: var(--secondary);
  padding: 10px;
  position: fixed;
  bottom: 0;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  color: white;
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 3px;
  line-height: 100%;
}
