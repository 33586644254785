.investment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   padding-bottom: 50px;
}

.investment-heading {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

// .investmeent-desc-text {
// }

.investment-mainlist {
  list-style: disc;
  font-size: 1.3rem;
}

.investment-list-title {
  color: var(--heading);
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.investment-sublist {
  list-style: disc;
  font-size: 1rem;
}
.investment-flowchart {
  width: 100%;
  margin-top: 30px;
}
