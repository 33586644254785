.research-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.research-heading {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
}

.research-subhead {
  margin-top: 30px;
  margin-bottom: 20px;
  color: var(--heading);
  font-size: 2rem;
}

.research-para {
  text-align: justify;
}

.research-galary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reserch-img {
  width: 150px;
  height: 200px;
  object-fit: cover;
  margin: 10px;
}

@media screen and (min-width: 992px) {
  .reserch-img {
    width: 300px;
    height: 400px;
  }
}
