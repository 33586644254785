:root {
  --primary: #ffffff;
  --secondary: #000000;
  --off-bg: #f6f9f8;
  --heading: #5f5f5f;
  --font-color: #5f5f5f;
  --hover-color: aqua;
}

.App {
  // background-color: var(--secondary);
  position: relative;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  // overflow: scroll;

  // z-index: -1;
}

.router-container {
  padding-top: 95px;
  padding-bottom: 50px;
}
