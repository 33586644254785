.sales-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--off-bg);
}

.sales-heading {
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--heading);
  font-size: 3rem;
  text-align: center;
}

.sales-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sales-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary);
  width: 320px;
  height: 400px;
  margin: 10px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  color: var(--font-color);
}

.sales-img {
  width: 300px;
  height: 170px;
}

.sales-item-details {
  width: 100%;
  //   height: 100%;
}

.sales-item-name {
  font-weight: bold;
  margin-top: 10px;
}

.sales-item-text {
  margin: 0;
}

.inquire-submit {
  all: unset;
  background-color: var(--secondary);
  color: white;
  padding: 10px 20px;
  transition: 300ms;
  width: 150px;
  height: 30px;
  text-align: center;
  margin-top: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inquire-submit:hover {
  color: var(--hover-color);

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
