.brand-container {
  position: relative;
  z-index: 2;
}

// .brand-background {
//   position: absolute;
//   clip-path: polygon(25% 25%, 100% 25%, 75% 75%, 25% 75%);
//   background-color: white;
//   height: 100px;
//   width: 200px;
//   z-index: 1;
//   left: -65px;
//   top: -20px;
// }

.brand-logo {
  max-width: 75px;
  z-index: 2;
}

// clip-path: polygon(25% 25%, 100% 25%, 75% 75%, 25% 75%);
