.carousel-container {
  max-height: 100vh !important;
  min-width: 100vw;
}

.carousel-component {
  max-height: calc(100vh - 95px) !important;
  //   min-height: 50vw !important;
}

.carousel-img {
  position: relative;
}

.carousel-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.397);
  width: 100%;
  height: 100%;
  //   z-index: 1;
}

.carousel-text {
  z-index: 2;
}

.carousel-caption {
  display: block !important;
}

.landing-p1 {
  margin-bottom: 0px;
}

.landing-h5 {
  margin-bottom: 0;
}

.landing-p2 {
  font-size: small;
  margin-bottom: 0px;
  line-height: 1.1;
}

@media screen and (min-width: 992px) {
  .landing-p1 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  .landing-h5 {
    margin-bottom: 0;
    font-size: 3rem;
  }

  .landing-p2 {
    font-size: small;
    margin-bottom: 100px;
    line-height: 1.1;
  }
}
