.inquire-container {
  background-color: var(--off-bg);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 150px);
  height: 100%;
}

.inquire-wrapper {
  background-color: white;
  color: var(--secondary);
  padding: 40px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  max-width: 600px;
  margin-bottom: 50px;
}

.aircraft-name {
  font-size: 1.2rem;
}
